import { useEffect, useRef, useState, type MouseEvent } from "react";

export const useDisableBodyScroll = (open: boolean) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);
};

export function useHandleOutsideClick<RefType extends HTMLElement>(
  callback: (e: MouseEvent<RefType>) => void
) {
  const ref = useRef<RefType>(null);

  useEffect(() => {
    /**
     * Run callback if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent<RefType>) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(event);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside as any);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside as any);
    };
  }, [ref, callback]);

  return ref;
}

export function useDebounce<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

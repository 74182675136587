import { SVGProps } from "react";
import Button from "../ui/Button";
import { textStyles } from "../../styles/typography";
import { cn } from "../../styles/utils";
import { useAtom } from "jotai";
import { orderStatusAtom } from "./store";

export default function PaymentFailed({
  onTryAgain,
}: {
  onTryAgain: () => void;
}) {
  const [paymentStatus] = useAtom(orderStatusAtom);

  const message = (
    {
      SUCCESS: "",
      PENDING: "",
      SLOT_BOOKED: "Your slot is booked. Try again to book another slot.",
      PAYMENT_FAILED: "There's a payment issue.",
    } as const
  )[paymentStatus!];

  return (
    <div className="flex flex-col items-center p-10">
      <IconFail className="mb-4" />
      <div className={cn(textStyles.h2, "text-red mb-3")}>
        {message ?? "There's a payment issue."}
      </div>
      <div className={cn(textStyles.body1, "text-blue-dark mb-10")}>
        Retry if you believe you're seeing this issue by mistake.
      </div>
      <Button type="button" variant="secondary" onClick={onTryAgain}>
        <IconRepeat /> Try Again
      </Button>
    </div>
  );
}

const IconFail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={120}
    height={120}
    fill="none"
    {...props}
  >
    <path
      fill="#F3F3F3"
      d="M60 120c33.137 0 60-26.863 60-60S93.137 0 60 0 0 26.863 0 60s26.863 60 60 60Z"
    />
    <rect width={80} height={60} x={20} y={30} fill="#fff" rx={4} />
    <path
      fill="#FD1414"
      d="M65.061 67.061 62.125 70l2.94 2.939a1.503 1.503 0 0 1-2.125 2.125L60 72.125l-2.939 2.94a1.503 1.503 0 0 1-2.125-2.125L57.875 70l-2.936-2.939A1.5 1.5 0 0 1 56 64.496c.399 0 .781.159 1.063.44L60 67.875l2.939-2.94a1.502 1.502 0 1 1 2.125 2.125l-.003.001ZM73.5 70A13.5 13.5 0 1 1 60 56.5 13.516 13.516 0 0 1 73.5 70Zm-3 0A10.5 10.5 0 1 0 60 80.5 10.511 10.511 0 0 0 70.5 70Z"
    />
    <path fill="#F3F3F3" d="M20 42h80v8H20z" />
  </svg>
);

const IconRepeat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#2A3D8C"
      d="M18.313 10a7.812 7.812 0 0 1-7.708 7.812H10.5a7.765 7.765 0 0 1-5.36-2.132.938.938 0 0 1 1.287-1.363 5.937 5.937 0 1 0-.154-8.487L4.788 7.187h1.337a.937.937 0 1 1 0 1.875h-3.75a.937.937 0 0 1-.938-.937v-3.75a.937.937 0 1 1 1.875 0v1.619l1.679-1.536A7.813 7.813 0 0 1 18.313 10Z"
    />
  </svg>
);

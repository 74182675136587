import { textStyles } from "../../styles/typography";
import { ccn } from "../../styles/utils";
import { formatCurrency } from "../../utils/currency";
import Button from "../ui/Button";
import { Spinner } from "../ui/Loading";

export default function SlotComponent({
  fromToTime,
  price,
  disabled,
  loading,
  handleBook,
}: {
  fromToTime: string;
  price?: number | null;
  disabled?: boolean;
  loading?: boolean;
  handleBook: () => void;
}) {
  return (
    <div className="flex items-center justify-between">
      <div>
        <div className={ccn(textStyles.sub1, "text-blue-dark")}>
          {fromToTime}{" "}
        </div>{" "}
        {/* {!loading && !disabled && <div>{formatCurrency(price ?? 0)}</div>} */}
      </div>
      {disabled ? (
        <Button type="button" variant="primary" small disabled>
          Sold Out
        </Button>
      ) : loading ? (
        <Spinner />
      ) : (
        <Button
          type="button"
          variant="primary"
          onClick={() => {
            window.scrollTo(0, 0);
            handleBook();
          }}
          small
        >
          Book for {formatCurrency(price ?? 0)}
        </Button>
      )}
    </div>
  );
}

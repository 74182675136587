import { atom } from "jotai";
import { CustomerDetails } from "./types";
import {
  DayPackagePriceResult,
  GetPaymentStatusQueryResult,
  ICreatePaymentIntentCommandResult,
} from "@repo/rq-api-public";

export const listingGuidAtom = atom<string | null>(null);
export const passengersAtom = atom<number>(1);
export const customerDetailsAtom = atom<CustomerDetails | null>(null);

export const currentCalendarMonthDateAtom = atom<Date>(new Date());
export const selectedDateAtom = atom<Date>(new Date());

export const selectedPackageAtom = atom<DayPackagePriceResult | null>(null);

export const orderDataAtom = atom<
  | (ICreatePaymentIntentCommandResult & {
      bookingGuid: string;
    })
  | null
>(null);

export const orderStatusAtom = atom<
  "PAYMENT_FAILED" | "SUCCESS" | "PENDING" | "SLOT_BOOKED" | null
>(null);

export const paymentResponseAtom = atom<GetPaymentStatusQueryResult | null>(
  null
);

export const isGroupPricingPackagesAtom = atom<boolean>(false);
export const filterPricingGuidAtom = atom<string | null>(null);

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";

import "./index.css";

Sentry.init({
  enabled: import.meta.env.PROD,

  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,

  dsn: "https://24305e48c83d72a1fbd48bcc68c818ed@iguana.observer/40",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, import.meta.env.VITE_API_HOST],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  beforeSend(event) {
    // Add custom tags or metadata
    event.tags = {
      ...event.tags,
      app_type: "widget", // Differentiate between 'widget' and 'admin'
    };

    event.extra = {
      ...event.extra,
      page_url: window.location.href, // Example: Add custom metadata like the current page URL
    };
    return event;
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={(errorInfo) => {
        const errorMessage = (errorInfo?.error as Error)?.message;
        return <p>{errorMessage ?? "An error has occurred"}</p>;
      }}
    >
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

import TextField from "../ui/TextField";
import AvailableSlots from "./AvailableSlots";
import Calendar from "./Calendar";
import CharterTypeSelect from "./CharterTypeSelect";
import { useAtom } from "jotai";
import { passengersAtom } from "./store";
import { textStyles } from "../../styles/typography";
import { ccn } from "../../styles/utils";
import AddFloatingLabel from "../ui/AddFloatingLabel";
import { useState } from "react";
import { useListingQuery } from "../../api/hooks";

type Props = {
  handleNextStep: () => void;
};

// Select date and time slot, charter type, passengers
// Show price
// Show button to book
export default function Step1({ handleNextStep }: Props) {
  const [passengers, setPassengers] = useAtom(passengersAtom);
  const [passengersEmpty, setPassengersEmpty] = useState<boolean>(false);

  const { data: listing } = useListingQuery();
  const passengersCapacity = listing?.boat?.passengers ?? 0;

  return (
    <div className="grid md:grid-cols-2 gap-8 md:gap-0 h-full md:overflow-hidden">
      {/* Calendar */}
      <div className="md:p-10 px-4 overflow-auto">
        <h1
          className={ccn(
            textStyles.h2,
            "text-blue-dark60 text-center md:mb-10 mb-4"
          )}
        >
          Choose Your Charter Date
        </h1>
        <div className="flex flex-col items-center justify-center relative isolate">
          <Calendar />
        </div>
      </div>

      {/* Trip details */}
      <div className="h-full overflow-auto" id="tripDetails">
        <div className="bg-grey-light md:p-10 px-4 py-6 pb-16 md:rounded-xl overflow-auto h-full">
          <h2
            className={ccn(
              textStyles.h2,
              "text-blue-dark60 text-center md:mb-10 mb-4"
            )}
          >
            Your Next Boat Adventure
          </h2>
          <div className="flex flex-col md:flex-row gap-1 md:gap-4 mb-5">
            <AddFloatingLabel label="Passengers" htmlFor="passengers">
              <TextField
                id="passengers"
                variant="dark"
                type="number"
                value={passengersEmpty ? "" : String(passengers)}
                min={1}
                max={passengersCapacity}
                onChange={(e) => {
                  if (!e.target.value) return setPassengersEmpty(true);
                  setPassengersEmpty(false);
                  const value = Number(e.target.value);
                  return setPassengers(
                    value < 1
                      ? 1
                      : value > passengersCapacity
                      ? passengersCapacity
                      : Number(e.target.value)
                  );
                }}
                className="w-full"
              />
            </AddFloatingLabel>
            <CharterTypeSelect />
          </div>

          <AvailableSlots handleNextStep={handleNextStep} />
        </div>
      </div>
    </div>
  );
}

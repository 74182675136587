import { Query } from "@repo/rq-api-public";
import { useAtomValue } from "jotai";
import { listingGuidAtom } from "../components/widget/store";

export const useListingQuery = () => {
  const listingGuid = useAtomValue(listingGuidAtom);
  const query = Query.useListingsGuidGETQuery(listingGuid!, {
    enabled: !!listingGuid,
  });
  return query;
};

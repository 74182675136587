import { useAtom, useAtomValue } from "jotai";
import Select from "../ui/Select";
import {
  filterPricingGuidAtom,
  isGroupPricingPackagesAtom,
  listingGuidAtom,
  selectedDateAtom,
} from "./store";
import AddFloatingLabel from "../ui/AddFloatingLabel";
import { Query } from "@repo/rq-api-public";
import { groupBy } from "../../utils/array";
import { useEffect } from "react";

export default function CharterTypeSelect() {
  const listingGuid = useAtomValue(listingGuidAtom);
  const selectedDay = useAtomValue(selectedDateAtom);

  const isGroupPricingPackages = useAtomValue(isGroupPricingPackagesAtom);
  const [filterPricingGuid, setFilterPricingGuid] = useAtom(
    filterPricingGuidAtom
  );

  const { data: calendarDayPricings, isLoading } =
    Query.useListingsGuidCalendarDayPricingsGETQuery({
      guid: listingGuid!,
      date: selectedDay,
    });

  const groupedDayPackagePrices = groupBy(
    calendarDayPricings?.dayPackagePrices ?? [],
    (packagePricing) => packagePricing.pricingGuid!
  );

  const charterTypes = Object.values(groupedDayPackagePrices)
    .map((group) => ({
      ...group[0]!,
      // override the isAvailable property to check if any of the packages are available
      isAvailable: group.some((packagePricing) => packagePricing.isAvailable),
    }))
    .filter(Boolean);

  const firstAvailablePricingGuid = charterTypes?.find(
    (charterType) => charterType.isAvailable
  )?.pricingGuid;

  useEffect(() => {
    // if the charter types are loaded it needs to be reselected
    if (!isLoading && charterTypes?.length > 0) {
      // try to persist the selected charter type
      const newCharterType = charterTypes?.find(
        (type) => type.pricingGuid === filterPricingGuid
      );

      if (newCharterType?.isAvailable) {
        setFilterPricingGuid(
          newCharterType?.pricingGuid ?? charterTypes?.[0]?.pricingGuid ?? null
        );
      } else {
        setFilterPricingGuid(
          firstAvailablePricingGuid ?? charterTypes?.[0]?.pricingGuid ?? null
        );
      }
    }
  }, [
    isLoading,
    charterTypes,
    filterPricingGuid,
    firstAvailablePricingGuid,
    setFilterPricingGuid,
  ]);

  if (charterTypes?.length === 1) {
    return null;
  }

  if (!isGroupPricingPackages) {
    return null;
  }

  return (
    <AddFloatingLabel label="Charter Type" htmlFor="charterType">
      <Select
        id="charterType"
        variant="dark"
        value={filterPricingGuid ?? ""}
        onChange={(e) => {
          setFilterPricingGuid(e.target.value);
        }}
        className="w-full"
        disabled={isLoading}
        defaultValue={
          firstAvailablePricingGuid ?? charterTypes?.[0]?.pricingGuid
        }
      >
        {charterTypes?.map((charterType) => (
          <option key={charterType.pricingGuid} value={charterType.pricingGuid}>
            {charterType.name}
          </option>
        ))}
      </Select>
    </AddFloatingLabel>
  );
}

import { parse } from "date-fns";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import {
  selectedDateAtom,
  currentCalendarMonthDateAtom,
  filterPricingGuidAtom,
  isGroupPricingPackagesAtom,
  listingGuidAtom,
} from "./store";

export default function useSyncQueryParams(setStep: (step: 1 | 2) => void) {
  // const [isLoaded, setIsLoaded] = useState(false);

  const setSelectedDate = useSetAtom(selectedDateAtom);
  const setCurrentCalendarMonth = useSetAtom(currentCalendarMonthDateAtom);
  const setFilterPricingGuid = useSetAtom(filterPricingGuidAtom);
  const setIsGroupPricingPackages = useSetAtom(isGroupPricingPackagesAtom);
  const setListingGuid = useSetAtom(listingGuidAtom);

  useEffect(() => {
    // if (isLoaded || charterTypesLoading || !charterTypes?.length) {
    //   return;
    // }
    // setIsLoaded(true);
    // TODO remove
    setStep(1);

    const searchParams = new URLSearchParams(window.location.search);

    const listingGuid = searchParams.get("listingGuid");
    if (!listingGuid) {
      throw new Error("Listing guid is required");
    }
    setListingGuid(listingGuid);

    const selectedDate = searchParams.get("date");
    const pricingGuid = searchParams.get("pricingGuid");
    const isGroupPricingPackages = searchParams.get("groupPricingPackages");

    // comes in format 2024-06-19
    const parsedDate = selectedDate
      ? parse(selectedDate, "yyyy-MM-dd", new Date())
      : null;

    if (parsedDate) {
      setSelectedDate(parsedDate);
      setCurrentCalendarMonth(parsedDate);
    }

    if (pricingGuid) {
      // If pricing guid is set, we need to set the filter and group pricing packages
      setFilterPricingGuid(pricingGuid);
      setIsGroupPricingPackages(true);
    }

    if (!pricingGuid) {
      setIsGroupPricingPackages(isGroupPricingPackages === "true");
    }
  }, []);
}

import { format } from "date-fns";
import { TimeOnly, TimeSpan } from "@repo/rq-api-public";

export const formatDate = (date: Date) => {
  // returns format: May 9, 2024
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const formatSlotTime = (date: Date) => {
  return format(date, "h:mm a");
};

export const formatToDoubleDigit = (value: number) => {
  return value < 10 ? `0${value}` : value;
};

function formatTimeTo12WithPeriod(time: TimeOnly | undefined): string {
  if (!time) {
    return "Invalid time";
  }

  let period: "AM" | "PM";
  let hour12: number;

  const hour = time.hour;

  if (hour === 0 || hour === 24) {
    hour12 = 12; // Midnight
    period = "AM";
  } else if (hour === 12) {
    hour12 = 12; // Noon
    period = "PM";
  } else if (hour > 12) {
    hour12 = hour - 12;
    period = "PM"; // PM for hours greater than 12
  } else {
    hour12 = hour;
    period = "AM"; // AM for hours less than 12
  }

  return `${formatToDoubleDigit(hour12)}:${formatToDoubleDigit(
    time.minute
  )} ${period}`;
}

export const renderFromToTimeString = (
  startTime: TimeOnly | undefined,
  duration: TimeSpan | undefined
) =>
  `${formatTimeTo12WithPeriod(startTime)} - ${formatTimeTo12WithPeriod(
    startTime && duration ? getEndTime(startTime, duration) : undefined
  )}`;

export const formatTimeToUrl = (time: TimeOnly | undefined) =>
  time ? `${time.hour}:${time.minute}` : "";

const getEndTime = (startTime: TimeOnly, duration: TimeSpan) => {
  // handles when duration is more than 24 hours
  const minutes = startTime.minute + duration.minutes!;
  const hours = startTime.hour + duration.hours! + Math.floor(minutes / 60);

  return new TimeOnly({
    hour: hours % 24,
    minute: minutes % 60,
  });
};

import { ProblemDetails } from "@repo/rq-api-public";
import { ApiValidationError } from "@repo/rq-api/customTypes";

export const handleApiErrors =
  <T extends string>(
    setGeneralErrors: (errors: any) => void,
    setValidationError?: (error: ApiValidationError<T>) => void
  ) =>
  (error: unknown) => {
    if (error instanceof ProblemDetails && error.status === 400) {
      if (setValidationError && error.errors?.length > 0) {
        const errors = error.errors as ApiValidationError<T>[];
        errors.forEach(({ field, message }) => {
          if (!field || !message) return;
          setValidationError({ field, message });
        });
      } else {
        setGeneralErrors(error.errors);
      }
    }
  };

import { useAtomValue } from "jotai";
import { passengersAtom, selectedDateAtom, selectedPackageAtom } from "./store";
import { formatDate, renderFromToTimeString } from "../../utils/date";
import { formatCurrency } from "../../utils/currency";
import { textStyles } from "../../styles/typography";
import { ccn } from "../../styles/utils";
import { useListingQuery } from "../../api/hooks";
import { Query } from "@repo/rq-api-public";

export default function BookingSummary() {
  const passengers = useAtomValue(passengersAtom);
  const selectedDate = useAtomValue(selectedDateAtom);
  const selectedPackage = useAtomValue(selectedPackageAtom);

  const { data: listing } = useListingQuery();

  const { data: calculation } = Query.usePricingsCalculateGETQuery({
    passengers,
    pricingGuid: selectedPackage?.pricingGuid,
  });

  if (!calculation || !listing || !selectedPackage) {
    return null;
  }

  return (
    <div>
      <h2
        className={ccn(
          textStyles.h2,
          "text-blue-dark text-center md:mb-10 mb-4"
        )}
      >
        Booking Summary
      </h2>
      <ul className="flex flex-col gap-3">
        <li className="flex justify-between">
          <div className={ccn(textStyles.body1, "text-blue-dark60")}>
            Charter
          </div>{" "}
          <div className={ccn(textStyles.sub1, "text-blue-dark text-right")}>
            {listing.boat?.listingTitle}
            <br /> {passengers} Passengers
          </div>
        </li>

        <li className="flex justify-between text-right border-b border-grey-primary pb-3">
          <div className={ccn(textStyles.body1, "text-blue-dark60")}>Date</div>{" "}
          <div className={ccn(textStyles.sub1, "text-blue-dark")}>
            {formatDate(selectedDate)} <br />{" "}
            {renderFromToTimeString(
              selectedPackage.startTime,
              selectedPackage.duration
            )}
          </div>
        </li>

        {calculation?.items?.map((item) => (
          <li key={item.name} className="flex justify-between">
            <div className={ccn(textStyles.body1, "text-blue-dark60")}>
              {item.name}
            </div>{" "}
            <div className={ccn(textStyles.sub1, "text-blue-dark")}>
              {formatCurrency(item.price ?? 0)}
            </div>
          </li>
        ))}

        <li className="flex justify-between border-t border-grey-primary pt-3">
          <div className={ccn(textStyles.h2, "text-blue-dark60")}>
            Total Amount
          </div>{" "}
          <div className={ccn(textStyles.h2, "text-blue-light")}>
            {formatCurrency(calculation.totalPrice ?? 0)}
          </div>
        </li>
      </ul>
    </div>
  );
}

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import TextArea from "../ui/TextArea";
import TextField from "../ui/TextField";
import { CustomerDetails } from "./types";
import {
  listingGuidAtom,
  orderDataAtom,
  customerDetailsAtom,
  passengersAtom,
  orderStatusAtom,
  selectedDateAtom,
  selectedPackageAtom,
} from "./store";
import { ccn, cn } from "../../styles/utils";
import { textStyles } from "../../styles/typography";
import {
  CreateMarketplaceBookingCommand,
  CreatePaymentIntentCommand,
  CreatePaymentIntentCommandResult,
  GetMarketplaceAvailableListingQuery,
  GetOrCreateCustomerByEmailCommand,
  Query,
} from "@repo/rq-api-public";
import { useState } from "react";
import Loading from "../ui/Loading";
import { handleApiErrors } from "../../utils/apiErrors";
import { ApiValidationError } from "@repo/rq-api/customTypes";
import { captureEvent } from "@sentry/react";
import { ErrorIcon } from "../ui/icons";

type FieldNames = "name" | "email" | "phone" | "message";
type ValidationError = ApiValidationError<FieldNames>;

export default function CustomerDetailsForm({
  setIsPaymentReady,
}: {
  setIsPaymentReady: (isReady: boolean) => void;
}) {
  const [isOrderCreateLoading, setIsOrderCreateLoading] = useState(false);

  const selectedDate = useAtomValue(selectedDateAtom);
  const listingGuid = useAtomValue(listingGuidAtom);
  const passengers = useAtomValue(passengersAtom);
  const selectedPackage = useAtomValue(selectedPackageAtom);

  const [customerDetails, setCustomerDetails] = useAtom(customerDetailsAtom);
  const setOrderData = useSetAtom(orderDataAtom);
  const setOrderStatus = useSetAtom(orderStatusAtom);

  const [failMessage, setFailMessage] = useState<string | null>(null);
  const [generalErrors, setGeneralErrors] = useState<unknown | null>(null);
  const [fieldErrors, setFieldErrors] = useState<{
    [key in FieldNames]?: ValidationError;
  }>({});

  const { mutateAsync: createCustomer } = Query.useCustomersPOSTMutation();
  const { mutateAsync: createBooking } = Query.useBookingsPOSTMutation();
  const { mutateAsync: createPaymentIntent } =
    Query.usePaymentsCreatePaymentIntentPOSTMutation();
  const { mutateAsync: listingAvailableMutate } =
    Query.useListingsAvailablePOSTMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsOrderCreateLoading(true);

    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData) as CustomerDetails;
    setCustomerDetails(data);

    if (!selectedPackage) {
      return;
    }

    try {
      let customerGuid: string | undefined = undefined;
      let bookingGuid: string | undefined = undefined;
      let paymentIntent: CreatePaymentIntentCommandResult;

      // Create customer
      try {
        customerGuid = await createCustomer(
          new GetOrCreateCustomerByEmailCommand({
            name: data.name,
            email: data.email,
            phone: data.phone,
          })
        );
      } catch (error) {
        setFailMessage("Failed to create customer");
        handleApiErrors<FieldNames>(setGeneralErrors, (validationError) => {
          setFieldErrors((prev) => ({
            ...prev,
            [validationError.field as string]: validationError,
          }));
        })(error);

        captureEvent({
          message: "Failed to create customer",
          level: "info",
          extra: {
            order: {
              ...data,
              selectedDate,
              listingGuid,
              passengers,
              pricingGuid: selectedPackage.pricingGuid!,
              startTime: selectedPackage.startTime!,
            },
            error,
          },
        });

        return;
      }

      if (!customerGuid) {
        throw new Error("Customer was not created");
      }

      // Create booking
      try {
        bookingGuid = await createBooking(
          new CreateMarketplaceBookingCommand({
            name: data.name,
            email: data.email,
            phone: data.phone,
            customerGuid,
            date: selectedDate,
            listingGuid: listingGuid!,
            passengers,
            pricingGuid: selectedPackage.pricingGuid!,
            startTime: selectedPackage.startTime!,
            notes: data.message,
          })
        );
      } catch (error) {
        setFailMessage("Failed to create booking");
        handleApiErrors(setGeneralErrors)(error);

        captureEvent({
          message: "Failed to create booking",
          level: "info",
          extra: {
            order: {
              ...data,
              selectedDate,
              listingGuid,
              passengers,
              pricingGuid: selectedPackage.pricingGuid!,
              startTime: selectedPackage.startTime!,
            },
            error,
          },
        });

        return;
      }

      // Check if the booking is available after creating the booking, so we have the data
      try {
        const availabilityResult = await listingAvailableMutate(
          new GetMarketplaceAvailableListingQuery({
            pricingGuid: selectedPackage?.pricingGuid,
            startDate: selectedDate,
            startTime: selectedPackage.startTime,
          })
        );

        if (!availabilityResult?.isAvailable) {
          setOrderStatus("SLOT_BOOKED");
          return;
        }
      } catch (error) {
        setFailMessage("Failed to check availability");
        handleApiErrors(setGeneralErrors)(error);

        captureEvent({
          message: "Failed to check availability",
          level: "info",
          extra: {
            order: {
              ...data,
              selectedDate,
              listingGuid,
              passengers,
              pricingGuid: selectedPackage.pricingGuid!,
              startTime: selectedPackage.startTime!,
            },
            error,
          },
        });

        return;
      }

      if (!bookingGuid) {
        throw new Error("Booking was not created");
      }

      // Create payment intent
      try {
        paymentIntent = await createPaymentIntent(
          new CreatePaymentIntentCommand({
            bookingGuid,
          })
        );
      } catch (error) {
        setFailMessage("Failed to create payment intent");
        handleApiErrors(setGeneralErrors)(error);

        captureEvent({
          message: "Failed to create payment intent",
          level: "info",
          extra: {
            order: {
              ...data,
              selectedDate,
              listingGuid,
              passengers,
              pricingGuid: selectedPackage.pricingGuid!,
              startTime: selectedPackage.startTime!,
            },
            error,
          },
        });

        return;
      }

      if (!paymentIntent) {
        throw new Error("Payment intent was not created");
      }

      setOrderData({ ...paymentIntent, bookingGuid });
      setIsPaymentReady(true);
    } catch (error) {
      const e = error as Error;
      setGeneralErrors(e?.message ?? error);

      captureEvent({
        message: e?.message ?? "Other error",
        level: "info",
        extra: {
          order: {
            ...data,
            selectedDate,
            listingGuid,
            passengers,
            pricingGuid: selectedPackage.pricingGuid!,
            startTime: selectedPackage.startTime!,
          },
          error,
        },
      });
    } finally {
      setIsOrderCreateLoading(false);
    }
  };

  if (isOrderCreateLoading) {
    return (
      <div>
        <h2
          className={ccn(
            textStyles.h2,
            "text-blue-dark60 md:mb-10 mb-4 text-center"
          )}
        >
          Add Your Contact Info
        </h2>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <h2
        className={ccn(
          textStyles.h2,
          "text-blue-dark60 md:mb-10 mb-4 text-center"
        )}
      >
        Add Your Contact Info
      </h2>
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit}
        id="contactInformation"
      >
        <TextField
          placeholder="First and Last Name"
          name="name"
          required
          defaultValue={customerDetails?.name}
          error={fieldErrors["name"]?.message}
        />

        <TextField
          placeholder="E-mail Address"
          name="email"
          type="email"
          pattern="[A-Za-z0-9._+\-']+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}"
          required
          defaultValue={customerDetails?.email}
          error={fieldErrors["email"]?.message}
        />

        <TextField
          placeholder="Phone Number"
          name="phone"
          type="tel"
          onChange={(e) => {
            const pattern =
              /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3,4})(?: *x(\d+))?\s*$/;
            const isValid = pattern.test(e.target.value);

            if (!isValid) {
              e.target.setCustomValidity("Invalid phone number");
            } else {
              e.target.setCustomValidity("");
            }
          }}
          required
          defaultValue={customerDetails?.phone}
          error={fieldErrors["phone"]?.message}
        />

        <TextArea
          placeholder="Note or Special Request (optional)"
          name="message"
          rows={1}
          defaultValue={customerDetails?.message}
          error={fieldErrors["message"]?.message}
        />
      </form>

      {generalErrors ? (
        <div className="border rounded-lg px-5 py-4 text-red bg-redLight mt-3">
          <div className={cn(textStyles.body1, "flex gap-2 mb-2 font-medium")}>
            <ErrorIcon /> {failMessage && failMessage}
          </div>
          {JSON.stringify(generalErrors)}
        </div>
      ) : null}
    </div>
  );
}

import { textStyles } from "../../styles/typography";
import { ccn, cn } from "../../styles/utils";
import BookingSummary from "./BookingSummary";
import Button from "../ui/Button";
import { CheckIcon } from "../ui/icons";
import { useAtomValue } from "jotai";
import { customerDetailsAtom, paymentResponseAtom } from "./store";
import { formatDate, formatSlotTime } from "../../utils/date";
import { useListingQuery } from "../../api/hooks";

export default function PaymentConfirmation() {
  const customerDetails = useAtomValue(customerDetailsAtom);
  // const selectedDay = useAtomValue(selectedDateAtom);
  // const selectedSlot = useAtomValue(selectedSlotAtom);
  const paymentResponse = useAtomValue(paymentResponseAtom);
  const transactionDate = paymentResponse?.paidOn;

  const { data: listing } = useListingQuery();

  const fullName = customerDetails?.name;

  return (
    <div className="px-3">
      <div className="bg-green-primary/15 text-green-primary rounded-lg flex items-center md:flex-row flex-col-reverse mb-5 p-3 md:p-10 gap-7">
        {listing?.boat?.boatPicture?.url && (
          <img
            src={listing?.boat?.boatPicture?.url}
            alt={listing.boat.listingTitle ?? ""}
            className="md:mx-auto h-44 md:h-32 object-cover rounded-xl w-full md:w-1/4"
          />
        )}

        <div>
          <h1
            className={ccn(
              textStyles.h1,
              "md:mb-3 mb-3 flex md:flex-row flex-col gap-2"
            )}
          >
            <CheckIcon className="w-8 h-8" /> Hello {fullName}, your booking is
            reserved
          </h1>

          <div className={cn(textStyles.body1, "md:max-w-screen-sm")}>
            A confirmation letter and all the details about the booking will be
            sent to your email.
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-1">
        {/* Trip details */}
        <div className="bg-grey-light md:p-10 p-3 rounded-xl mb-10">
          {/* <div className="mb-10"> */}
          {/* <div className={cn("uppercase text-center mt-7", textStyles.sub1)}>
              <div className="text-blue-dark">{boat?.Label}</div>
              <div className="text-blue-light">
                {formatDate(selectedDay)}
                {selectedSlot && (
                  <>
                    ; {formatSlotTime(selectedSlot?.interval.start)} —{" "}
                    {formatSlotTime(selectedSlot?.interval.end)}
                  </>
                )}
              </div>
            </div> */}
          {/* </div> */}

          <BookingSummary />
          <div className={cn(textStyles.sub1, "text-blue-dark mb-4 mt-6")}>
            Payment Details
          </div>
          <ul className="text-blue-dark flex flex-col gap-3">
            <li className="flex items-center justify-between">
              <div className="text-blue-dark60">Card</div>{" "}
              <div>*{paymentResponse?.cardLast4}</div>
            </li>
            <li className="flex items-center justify-between">
              <div className="text-blue-dark60">Date</div>{" "}
              {transactionDate && (
                <div>
                  {formatDate(transactionDate)};{" "}
                  {formatSlotTime(transactionDate)}
                </div>
              )}
            </li>
          </ul>

          <div className="">
            <div className={cn(textStyles.sub1, "text-blue-dark mb-4 mt-7")}>
              Contact details
            </div>
            <ul className="flex flex-col gap-3 mb-3">
              <li className="flex justify-between">
                <div className={cn(textStyles.body1, "text-blue-dark60")}>
                  Full Name
                </div>
                <div className={cn(textStyles.sub1, "text-blue-dark")}>
                  {fullName}
                </div>
              </li>
              <li className="flex justify-between">
                <div className={cn(textStyles.body1, "text-blue-dark60")}>
                  Phone
                </div>
                <div className={cn(textStyles.sub1, "text-blue-dark")}>
                  {customerDetails?.phone}
                </div>
              </li>
              <li className="flex justify-between">
                <div className={cn(textStyles.body1, "text-blue-dark60")}>
                  E-mail
                </div>
                <div className={cn(textStyles.sub1, "text-blue-dark")}>
                  {customerDetails?.email}
                </div>
              </li>
              <li className="flex justify-between">
                <div className={cn(textStyles.body1, "text-blue-dark60")}>
                  Message
                </div>
                <div className={cn(textStyles.sub1, "text-blue-dark")}>
                  {customerDetails?.message ?? "-"}
                </div>
              </li>
            </ul>
          </div>

          {/* {boat.Agent && (
            <div className="flex items-center flex-col mt-10">
              <div
                className={ccn(
                  "text-green-primary text-center mb-3",
                  textStyles.h1
                )}
              >
                Need Assistance? Contact your Agent
              </div>
              <div className="flex justify-center items-center gap-3">
                <img
                  src={boat.Picture.Url}
                  alt={boat.Label}
                  className="h-32 object-cover rounded-xl w-1/4"
                />
                <div
                  className={ccn(
                    "flex flex-col gap-2 text-blue-dark",
                    textStyles.sub1
                  )}
                >
                  <div>{boat.Agent.Name}</div>
                  <div>{boat.Agent.Phone}</div>
                  <a href={`mailto:${boat.Agent.Email}`}>{boat.Agent.Email}</a>
                </div>
              </div>
            </div>
          )} */}

          <Button
            type="button"
            variant="primary"
            className="mt-10 w-full"
            onClick={() => {
              window.parent.postMessage(
                {
                  close: true,
                },
                "*"
              );
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}

import { type ComponentPropsWithoutRef } from "react";
import { cn } from "../../styles/utils";

// import { textStyles } from "~/styles/typography";

export const commonStyles =
  "w-full px-5 py-4 rounded-lg transition-colors border";
export const variantStyles = {
  light: {
    enabled:
      "bg-white text-blue-dark placeholder:text-blue-dark60 border-blue-dark20",
  },
  dark: {
    enabled:
      "bg-grey-light text-blue-dark border-blue-dark20 active:border-blue-200 placeholder:text-blue-100",
  },
};

type Props = {
  variant?: keyof typeof variantStyles;
  error?: string;
} & ComponentPropsWithoutRef<"input">;

export default function TextField({
  variant = "light",
  className,
  error,
  ...props
}: Props) {
  return (
    <div className="w-full">
      <input
        {...props}
        className={cn(
          // textStyles.sub2,
          commonStyles,
          variantStyles[variant].enabled,
          className,
          error && "border-red"
        )}
      />
      {error && <p className="text-red text-xs mt-1 px-3">{error}</p>}
    </div>
  );
}

import { PropsWithChildren, useState } from "react";
import Step1 from "./widget/Step1";
import Step2 from "./widget/Step2";
import { useAtom } from "jotai";
import { orderStatusAtom } from "./widget/store";
import Loading from "./ui/Loading";
import Header from "./widget/Header";
import PaymentFailed from "./widget/PaymentFailed";
import PaymentConfirmation from "./widget/PaymentConfirmation";
import PaymentPending from "./widget/PaymentPending";
import { useListingQuery } from "../api/hooks";
import useSyncQueryParams from "./widget/useSyncQueryParams";

export default function Widget() {
  const [step, setStep] = useState<1 | 2>(1);
  const [orderStatus, setOrderStatus] = useAtom(orderStatusAtom);

  useSyncQueryParams(setStep);

  const { isLoading, isError } = useListingQuery();

  if (isLoading) {
    return (
      <Layout showInfo={false}>
        <div className="h-full flex items-center justify-center">
          <Loading />
        </div>
      </Layout>
    );
  }

  if (isError) {
    return <Layout showInfo={false}>Error loading boat</Layout>;
  }

  if (orderStatus === "SUCCESS") {
    return (
      <Layout>
        <PaymentConfirmation />
      </Layout>
    );
  }

  if (orderStatus === "PENDING") {
    return (
      <Layout>
        <PaymentPending />
      </Layout>
    );
  }

  if (orderStatus === "PAYMENT_FAILED" || orderStatus === "SLOT_BOOKED") {
    return (
      <Layout>
        <PaymentFailed
          onTryAgain={() => {
            setOrderStatus(null);

            if (orderStatus === "SLOT_BOOKED") {
              setStep(1);
            }
          }}
        />
      </Layout>
    );
  }

  return (
    <Layout>
      {step === 1 && <Step1 handleNextStep={() => setStep(2)} />}
      {step === 2 && <Step2 handlePrevStep={() => setStep(1)} />}
    </Layout>
  );
}

const Layout = ({
  children,
  showInfo,
}: PropsWithChildren<{ showInfo?: boolean }>) => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full max-w-5xl bg-white md:rounded-3xl min-h-screen md:min-h-[unset]">
        <Header showBoatInfo={showInfo} />

        <div className="md:p-5 pt-6">
          <div className="md:max-h-[670px] md:h-[670px] md:overflow-hidden">
            <div className="h-full overflow-auto">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

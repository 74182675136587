import { setBaseUrl, getAxios } from "@repo/rq-api-public";

setBaseUrl(import.meta.env.VITE_API_HOST);

const axios = getAxios();

// Add a request interceptor to add the api key token to the ApiKey header
axios.interceptors.request.use(
  (config) => {
    config.headers.ApiKey = import.meta.env.VITE_API_KEY;

    return config;
  },
  (error) => Promise.reject(error)
);

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Widget from "./components/Widget";

import "./api/interceptors";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 3 seconds
      staleTime: 1000 * 3,
      retry: 1,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Widget />
    </QueryClientProvider>
  );
}

export default App;

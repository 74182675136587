import {
  CaptionLabel,
  CaptionProps,
  DayMouseEventHandler,
  DayPicker,
  useNavigation,
} from "react-day-picker";
import { ccn, cn } from "../../styles/utils";
import { useAtom, useAtomValue } from "jotai";
import {
  currentCalendarMonthDateAtom,
  listingGuidAtom,
  selectedDateAtom,
} from "./store";
import { textStyles } from "../../styles/typography";
import { SVGProps, useEffect } from "react";
import { Spinner } from "../ui/Loading";
import {
  addDays,
  addMonths,
  endOfMonth,
  isSameDay,
  isToday,
  startOfMonth,
} from "date-fns";
import {
  CalendarDayAvailability,
  GetMarketplaceCalendarDaysQueryResult,
  Query,
} from "@repo/rq-api-public";

const isAvailable = (
  day: Date,
  calendarDays: GetMarketplaceCalendarDaysQueryResult[]
) => {
  return !calendarDays.some(
    ({ availability, day: bookedDay }) =>
      availability === CalendarDayAvailability.SoldOut &&
      isSameDay(bookedDay!, day)
  );
};

export default function Calendar() {
  const [month, setMonth] = useAtom(currentCalendarMonthDateAtom);
  const [selectedDay, setSelectedDay] = useAtom(selectedDateAtom);
  const listingGuid = useAtomValue(listingGuidAtom);

  const { data: calendarDays = [], isLoading: isCalendarLoading } =
    Query.useListingsGuidCalendarDaysGETQuery({
      guid: listingGuid!,
      from: startOfMonth(month),
      to: endOfMonth(addMonths(month, 1)),
    });

  useEffect(() => {
    if (!calendarDays.length) {
      return;
    }
    // If today is disabled, select the next available day
    const disabledToday = calendarDays.find(
      ({ availability, day }) =>
        availability === CalendarDayAvailability.SoldOut && isToday(day!)
    );
    if (!disabledToday) {
      return;
    }

    let nextDay = addDays(new Date(), 1);

    while (!isAvailable(nextDay, calendarDays)) {
      nextDay = addDays(nextDay, 1);
    }

    setSelectedDay(nextDay);
    setMonth(nextDay);
  }, [calendarDays, setSelectedDay, setMonth]);

  const handleDayClick: DayMouseEventHandler = (day) => {
    setSelectedDay(day);
    // scroll to trip details
    document.getElementById("tripDetails")?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div className={cn("mt-8 md:mt-0")}>
      <div className={"relative isolate"}>
        <DayPicker
          id="calendar"
          mode="single"
          defaultMonth={new Date()}
          fromDate={new Date()}
          selected={selectedDay}
          onDayClick={handleDayClick}
          month={month}
          onMonthChange={setMonth}
          modifiers={{
            partiallyBooked:
              calendarDays
                .filter(
                  ({ availability }) =>
                    availability === CalendarDayAvailability.PartiallyAvailable
                )
                .map(({ day }) => day!) ?? [],
          }}
          disabled={[
            { before: new Date() },
            ...(calendarDays
              .filter(
                ({ availability }) =>
                  availability === CalendarDayAvailability.SoldOut
              )
              .map(({ day }) => day!) ?? []),
          ]}
          modifiersClassNames={{
            partiallyBooked: "rdp-day_partiallyBooked",
          }}
          classNames={{
            caption_label: ccn(textStyles.h2, "text-blue-dark"),
          }}
          components={{
            Caption: CustomCaptionComponent,
          }}
        />
        {isCalendarLoading && (
          <div className="flex items-center justify-center absolute bg-white/75 inset-0 z-10">
            <Spinner />
          </div>
        )}
      </div>

      <div className="flex flex-wrap gap-3 text-xs text-blue-dark mt-5">
        <div className="flex items-center gap-2">
          <div
            className={ccn("rdp-button", "rdp-day", "!w-8 !h-8 flex-1")}
          ></div>
          <div>Fully Available</div>
        </div>
        <div className="flex items-center gap-2">
          <div
            className={ccn(
              "rdp-button",
              "rdp-day_partiallyBooked",
              "!w-8 !h-8 flex-1"
            )}
          ></div>
          <div>Partially Available</div>
        </div>
        <div className="flex items-center gap-2">
          <div
            className={ccn(
              "rdp-button",
              "rdp-day_disabled",
              "!w-8 !h-8 flex-1"
            )}
          ></div>
          <div>Sold Out</div>
        </div>
      </div>
    </div>
  );
}

function CustomCaptionComponent(props: CaptionProps) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  return (
    <h2 className="flex justify-between">
      <button
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
        className="p-2 hover:bg-grey-light rounded-lg disabled:bg-transparent transition-colors"
      >
        <IconChevronRight className="rotate-180" />
      </button>
      <CaptionLabel {...props} />
      <button
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
        className="p-2 hover:bg-grey-light rounded-lg disabled:bg-transparent transition-colors"
      >
        <IconChevronRight />
      </button>
    </h2>
  );
}

const IconChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#2A3D8C"
      d="m9.705 6-1.41 1.41 4.58 4.59-4.58 4.59L9.705 18l6-6-6-6Z"
    />
  </svg>
);

import { type ComponentPropsWithoutRef } from "react";

// import { textStyles } from "~/styles/typography";
import { ccn } from "../../styles/utils";
import { commonStyles, variantStyles } from "./TextField";

type Props = {
  variant?: keyof typeof variantStyles;
  error?: string;
} & ComponentPropsWithoutRef<"textarea">;

export default function TextArea({
  variant = "light",
  rows = 5,
  error,
  ...props
}: Props) {
  return (
    <div>
      <textarea
        {...props}
        rows={rows}
        className={ccn(
          // textStyles.sub2,
          commonStyles,
          variantStyles[variant].enabled,
          props.className,
          error && "border-red"
        )}
      />
      {error && <p className="text-red text-xs mt-1 px-3">{error}</p>}
    </div>
  );
}
